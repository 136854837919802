import React from 'react'
import { Link } from 'react-router-dom';

class Nav extends React.Component {
  render() {
    return (
      <header id="header" className="clearfix">
        <div id="logo" className="float-left"><img src={require("../../assets/img/xiaowulogo.png")} alt="logo of xiaowu tech" /></div>
        <ul className="nav clearfix float-left">
          <li className="float-left">
            <Link to="/">
              网站首页<br/><span>Home page</span>
            </Link>
          </li>
          {/*<li className="float-left">*/}
          {/*  <Link to="/aboutUs">*/}
          {/*    关于我们<br/><span>About us</span>*/}
          {/*  </Link>*/}
          {/*</li>*/}
          {/*<li className="float-left">*/}
          {/*  <Link to="/latestProjects">*/}
          {/*    最新项目<br/><span>Latest projects</span>*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li className="float-left">
            <a href="/#partners">
              合作伙伴<br/><span>Partners</span>
            </a>
          </li>
          {/*<li className="float-left">*/}
          {/*  <a href="/#charging-items">*/}
          {/*    收费资讯<br/><span>Fee information</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li className="float-left">*/}
          {/*  <a href="/#team">*/}
          {/*    团队介绍<br/><span>Our team</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
          <li className="float-left">
            <a href="/#index-footer">
              加入我们<br/><span>To join us</span>
            </a>
          </li>
        </ul>
      </header>
    )
  }
}

export default Nav;
