import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import Nav from '../nav'

class Header extends React.Component {
  componentWillUnmount() {
    if (this.swiper) { // 销毁swiper
      this.swiper.destroy()
    }
  }
  componentDidMount() {
    this.swiper = new Swiper ('#first-screen', {
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: false,
      autoplay: {
        delay: 4000, // 自动切换的时间间隔
        disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay
      }
    })
  }

  render() {
    return (
      <div id="fullpage">
        <Nav />
        <div id="first-screen" className="swiper-container section">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="slide-top">
                <img src={require("../../../assets/img/internet.png")} alt="" className="slider-banner1" />
              </div>
              <div className="slide-bottom">
                <p>北京小悟科技有限公司于2016年03月02日登记成立，注册资金为1000万元。公司经营范围包括技术开发、技术转让、技术咨询、技术服务、软件开发等。<br/>一直致力于高性能计算，高并发网络服务系统及大数据领域的技术研发。是国家高新技术企业，中关村高新技术企业。凭借雄厚的技术实力，<br/>公司成为电信运营商核心IT技术服务商之一。</p>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="slide-top">
                <img src={require("../../../assets/img/internet_1.png")} alt="" className="slider-banner2" />
              </div>
              <div className="slide-bottom">
                <h2>大数据服务体系</h2>
                <p>多年的电信行业大数据处理经验，具有日处理超过2000亿条数据的实际项目经验。高性能流式处理引擎，支持每秒30万条，<br/>每天250亿条数据的实时事件触发计算。性能超过15万qps的数据能力开放引擎。</p>
              </div>
            </div>
            <div className="swiper-slide clearfix">
              <div className="slide-left-new float-left">
                <h2>权益类商城</h2>
                <p>从事权益类商品电子商城的研发、运营和服务，基于我国各大公司积分商城的现状，并且结合消费者年轻化等趋势，对接各大互联网公司的权益类商品，为不同类型的公司提供用户的维系、拉新、促活等运营服务，结合用户行为数据分析，提供精准化营销和活动策划方案，满足用户的个性化需求，提高业务量和用户活跃度，降低企业营销成本，并为企业的用户分析和偏好提供决策依据。</p>
              </div>
              <div className="slide-right-new float-right">
                <img src={require("../../../assets/img/internet_2.png")} alt="" className="slider-banner3" />
              </div>
            </div>
            <div className="swiper-slide clearfix">
              <div className="slide-left-new float-left">
                <h2>小悟科技行业短信网关</h2>
                <p>2017年起，在中国联通多个全国性业务中商用，近2年运行中故障停机率为0。提供了适应于互联网短信业务开展的大量新特性，采用了最新移动互联网技术架构的电信级行业短信网关，在互联网短信网关业务方面，有完善的基础功能，为运营商提供了特殊业务支撑。</p>
              </div>
              <div className="slide-right-new float-right">
                <img src={require("../../../assets/img/internet_3.png")} alt="" className="slider-banner4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header;
