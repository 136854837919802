/**
 * File Description:
 * 公共工具类
 * Created by   AZheng       389566364@qq.com      02/18/2020
 * CopyRight    北京小悟科技有限公司      http://www.microwu.com
 * Method List:
 *  1. GetMobelType 获得当前设备的类型
 * Update History:
 * Author       Time            Contennt
 */

export function GetMobelType() {
  let u = window.navigator.userAgent;
  return {
    trident: u.indexOf('Trident') > -1, // IE内核
    presto: u.indexOf('Presto') > -1, // opera内核
    Alipay: u.indexOf('Alipay') > -1, // 支付宝
    webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或者uc浏览器
    iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, // 是否为iPhone或者安卓QQ浏览器
    // iPhone: u.match(/iphone|ipod|ipad/),//
    iPad: u.indexOf('iPad') > -1, // 是否为iPad
    webApp: u.indexOf('Safari') === -1, // 是否为web应用程序，没有头部与底部
    weixin: u.indexOf('MicroMessenger') > -1, // 是否为微信浏览器
    qq: u.match(/\sQQ/i) !== null, // 是否QQ
    Safari: u.indexOf('Safari') > -1 // Safari浏览器
  };
}
