import React from 'react'
// import { Link } from 'react-router-dom';

class AboutUs extends React.Component {
    render() {
        return (
            <div id="about-us">
                <div className="header-row">
                    <h2>关于我们</h2>
                    <h3>About&nbsp;&nbsp;us</h3>
                </div>
                <div className="content-row clearfix">
                    <div className="float-left">
                        <img src={require('../../../assets/img/about_us.jpg')} alt="about us" />
                    </div>
                    <div className="float-right">
                        <h4>专注 互联网+大数据<br />提供优质的产品和服务体验</h4>
                        <p>2016年以来，核心研发团队解决了中国联通电子渠道大数据智能分析、高性能检索及高并发查询的技术难题，研发了高性能数据处理引擎，处理能力达到了单服务器百万条/秒，同时针对通信领域的风险控制、反欺诈
                            和用户行为分析等大数据智能分析需求，研发了独创的大数据智能计算框架，具备千亿条/天的数据处理能力，填补了运营商在数据智能分析处理领域多年的行业空白。</p>
                        {/*<div>*/}
                        {/*  /!*<Link to="/aboutUs">了解我们</Link>*!/*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs;
