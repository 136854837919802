import React from 'react'
import {withRouter} from 'react-router-dom'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'

class NewsMobile extends React.Component {
  componentDidMount() {
    new Swiper('.news-swiper-container', {
      initialSlide: 0,
      slidesPerView: 1,
      centeredSlides: true,
      paginationClickable: false,
      spaceBetween: 30,
      autoplay: {
        delay: 2000, // 自动切换的时间间隔
        disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay
      },
      pagination: {
        el: '.news-swiper-pagination',
      },
    });
  }
  turnTo(type){
    this.props.history.push('/newsInformation?type='+type);
    return;
  }
  render() {
    return (
      <div id="newsMobile" style={{display:'none'}}>
        <h4>最新资讯</h4>
        <h2>网络行业最新新闻</h2>
        <div className="news-swiper-container">
          <ul className="swiper-wrapper">
            <li className="swiper-slide active" onClick={()=>this.turnTo(1)}>
              <div className="side-image"><img src={require("../../../assets/img/news_side_image2.jpg")} alt="" /></div>
              <div className="content">
                <h3>Recent NewsPage</h3>
                <h5>2017-03-21 9:58</h5>
                <hr />
                <p>2018年，“上市”毫无疑问是互联网科技行业的主题之一。随着Bilibili/爱奇艺、虎牙直播、拼多多、优信二手车美国上市，小米、美团等在中国香港挂牌，第三次互联网科技企业上市达到高潮。</p>
                <div><i></i>13 评论</div>
              </div>
            </li>
            <li className="swiper-slide active" onClick={()=>this.turnTo(2)}>
              <div className="side-image"><img src={require("../../../assets/img/news_side_image.jpg")} alt="" /></div>
              <div className="content">
                <h3>AI（人工智能）</h3>
                <h5>2018-08-07 11:30</h5>
                <hr />
                <p>人工智能是2018年最热门的科技词汇之一，运营商、互联网企业、IT企业、手机厂商等，都希望借助AI实现网络、产品与方案的变革。</p>
                <div><i></i>20 评论</div>
              </div>
            </li>
            <li className="swiper-slide active" onClick={()=>this.turnTo(3)}>
              <div className="side-image"><img src={require("../../../assets/img/news_side_image1.jpg")} alt="" /></div>
              <div className="content">
                <h3>5G承载</h3>
                <h5>2018-12-20 9:30</h5>
                <hr />
                <p>5G商用，承载先行。随着5G商用的加速，业界对5G承载的关注度也越来越高。2018年无论是标准组织还是运营商还是设备商在5G承载领域都取得了非常关键的新进展</p>
                <div><i></i>24 评论</div>
              </div>
            </li>
            <li className="swiper-slide active" onClick={()=>this.turnTo(4)}>
              <div className="side-image"><img src={require("../../../assets/img/news_side_image.jpg")} alt="" /></div>
              <div className="content">
                <h3>IPv6</h3>
                <h5>2019-01-12 10:00</h5>
                <hr />
                <p>随着万物互联时代的到来，IPv4地址已捉襟见肘，IPv6改造迫在眉睫。</p>
                <div><i></i>16 评论</div>
              </div>
            </li>
            <li className="swiper-slide active" onClick={()=>this.turnTo(5)}>
              <div className="side-image"><img src={require("../../../assets/img/news_side_image2.jpg")} alt="" /></div>
              <div className="content">
                <h3>Recent NewsPage</h3>
                <h5>2017-03-21 9:58</h5>
                <hr />
                <p>2018年，“上市”毫无疑问是互联网科技行业的主题之一。随着Bilibili/爱奇艺、虎牙直播、拼多多、优信二手车美国上市，小米、美团等在中国香港挂牌，第三次互联网科技企业上市达到高潮。</p>
                <div><i></i>13 评论</div>
              </div>
            </li>
            <li className="swiper-slide active" onClick={()=>this.turnTo(6)}>
              <div className="side-image"><img src={require("../../../assets/img/news_side_image1.jpg")} alt="" /></div>
              <div className="content">
                <h3>AI（人工智能）</h3>
                <h5>2018-08-07 11:30</h5>
                <hr />
                <p>人工智能是2018年最热门的科技词汇之一，运营商、互联网企业、IT企业、手机厂商等，都希望借助AI实现网络、产品与方案的变革。</p>
                <div><i></i>20 评论</div>
              </div>
            </li>
            <li className="swiper-slide active" onClick={()=>this.turnTo(7)}>
              <div className="side-image"><img src={require("../../../assets/img/news_side_image.jpg")} alt="" /></div>
              <div className="content">
                <h3>5G承载</h3>
                <h5>2018-12-20 9:30</h5>
                <hr />
                <p>5G商用，承载先行。随着5G商用的加速，业界对5G承载的关注度也越来越高。2018年无论是标准组织还是运营商还是设备商在5G承载领域都取得了非常关键的新进展</p>
                <div><i></i>24 评论</div>
              </div>
            </li>
          </ul>
          <div className="news-swiper-pagination"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(NewsMobile);
