import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <footer id="index-footer">
        <div className="logo"><img src={require("../../../assets/img/xiaowulogo.png")} alt="logo of xiaowu tech" /></div>
        <div className="info">
          <ul>
            <li>
              <div className="left">
                <div className="icon-phone"></div>
                <p>联系电话</p>
              </div>
              <div className="right">
                <p>010 - 6739 - 6329<br/>18501364533</p>
              </div>
            </li>
            <li>
              <div className="left">
                <div className="icon-mail"></div>
                <p>企业邮箱</p>
              </div>
              <div className="right">
                <p>hr@microwu.com</p>
              </div>
            </li>
            <li>
              <div className="left">
                <div className="icon-address"></div>
                <p>地址</p>
              </div>
              <div className="right">
                <p>北京市朝阳区化工路59号<br/>焦奥中心B座205室</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="copyright">© 2019 小悟科技 . All Rights Reserved.</div>
      </footer>
    )
  }
}

export default Footer;
