import React from 'react'
import '../../assets/css/main.css'

import Header from '../../components/web/home/header'
import AboutUs from "../../components/web/home/aboutUs";
import Projects from "../../components/web/home/projects";
import Partners from "../../components/web/home/partners";
import News from "../../components/web/home/news";
import Charging from "../../components/web/home/charging";
import Team from "../../components/web/home/team";
// import Location from "../../components/web/home/location";
import Footer from "../../components/web/home/footer";

class Homepage extends React.Component {
  render() {
    return (
      <div className="home">
        <Header />
        <AboutUs />
        <Projects />
        <Partners />
        <News />
        <Charging />
        <Team />
        {/*<Location />*/}
        <Footer />
      </div>
    )
  }
}

export default Homepage;
