import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import NavMobile from '../nav'

class HeaderMobile extends React.Component {
  componentWillUnmount() {
    if (this.swiper) { // 销毁swiper
      this.swiper.destroy()
    }
  }
  componentDidMount() {
    this.swiper = new Swiper ('#homeBannerMobile', {
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: false,
      autoplay: {
        delay: 4000, // 自动切换的时间间隔
        disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay
      }
    })
  }

  render() {
    return (
      <div id="headerMobile">
        <NavMobile />
        <div id="homeBannerMobile" className="swiper-container section">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <h3>电信IT技术服务商</h3>
              <p>专注于高性能计算、高并发网络技术及大数据技术服务</p>
              <img src={require("../../../assets/img/internet.png")} alt="" className="slider-mobile-banner1" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderMobile;
