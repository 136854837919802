import React from 'react'
// import { Link } from 'react-router-dom';

class Projects extends React.Component {
  render() {
    return (
      <div id="projects">
        <h2>主营业务</h2>
        <ul className="clearfix">
          <li className="float-left">
            <div><img src={require('../../../assets/img/projects_1.jpg')} alt="" /></div>
            <p>与阿里巴巴合作</p>
            {/*<i>$29</i>*/}
            {/*<div className="project-shadow">*/}
            {/*  <Link to="/latestProjects"><i className="icon-project-detail"></i>查看详情</Link>*/}
            {/*</div>*/}
          </li>
          <li className="float-left">
            <div><img src={require('../../../assets/img/projects_6.jpg')} alt="" /></div>
            <p>中小企业短信业务</p>
            {/*<i>$39</i>*/}
            {/*<div className="project-shadow">*/}
            {/*  <Link to="/latestProjects"><i className="icon-project-detail"></i>查看详情</Link>*/}
            {/*</div>*/}
          </li>
          {/*<li className="float-left">*/}
          {/*  <div><img src={require('../../../assets/img/projects_3.jpg')} alt="" /></div>*/}
          {/*  <p>腾讯王卡业务</p>*/}
          {/*  <i>$29</i>*/}
          {/*  <div className="project-shadow">*/}
          {/*    <Link to="/latestProjects"><i className="icon-project-detail"></i>查看详情</Link>*/}
          {/*  </div>*/}
          {/*</li>*/}
          {/*<li className="float-left">*/}
          {/*  <div><img src={require('../../../assets/img/projects_4.jpg')} alt="" /></div>*/}
          {/*  <p>北京工业大学</p>*/}
          {/*  <i className="icon-free">FREE</i>*/}
          {/*  <div className="project-shadow">*/}
          {/*    <Link to="/latestProjects"><i className="icon-project-detail"></i>查看详情</Link>*/}
          {/*  </div>*/}
          {/*</li>*/}
          <li className="float-left">
            <div><img src={require('../../../assets/img/projects_5.jpg')} alt="" /></div>
            <p>易宝支付</p>
            {/*<i>$39</i>*/}
            {/*<div className="project-shadow">*/}
            {/*  <Link to="/latestProjects"><i className="icon-project-detail"></i>查看详情</Link>*/}
            {/*</div>*/}
          </li>
          <li className="float-left">
            <div><img src={require('../../../assets/img/projects_7.jpg')} alt="" /></div>
            <p>权益商城</p>
            {/*<i>$39</i>*/}
            {/*<div className="project-shadow">*/}
            {/*  <Link to="/latestProjects"><i className="icon-project-detail"></i>查看详情</Link>*/}
            {/*</div>*/}
          </li>
          {/*<li className="float-left">*/}
          {/*  <div><img src={require('../../../assets/img/projects_6.jpg')} alt="" /></div>*/}
          {/*  <p>赞成支付</p>*/}
          {/*  <i className="icon-free">FREE</i>*/}
          {/*  <div className="project-shadow">*/}
          {/*    <Link to="/latestProjects"><i className="icon-project-detail"></i>查看详情</Link>*/}
          {/*  </div>*/}
          {/*</li>*/}
        </ul>
        {/*<div>*/}
        {/*  <Link to="/latestProjects" className="view-all-projects">查看所有<i>&#10140;</i></Link>*/}
        {/*</div>*/}
      </div>
    )
  }
}

export default Projects;
