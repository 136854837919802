import React from 'react'
// import { Link } from 'react-router-dom';

class NavMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuExpanded: false,
    };
  }

  render() {
    return (
      <header id="navMobile" className="clearfix">
        <div className="logo-mobile float-left"><img src={require("../../assets/img/xiaowulogo.png")} alt="logo of xiaowu tech" /></div>
        {/*<div className="menu float-right">*/}
        {/*  <img onClick={() => this.setState({isMenuExpanded: true})} src={require("../../assets/img/mobile/menu.png")} alt=""/>*/}
        {/*</div>*/}
        <div className={this.state.isMenuExpanded ? "expanded nav float-left" : "nav float-left"}>
          <div onClick={() => this.setState({isMenuExpanded: false})} className="mask"> </div>
          <ul className="clearfix">
            {/*<li>*/}
            {/*  <Link to="/">*/}
            {/*    网站首页*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/aboutUs">*/}
            {/*    关于我们*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/latestProjects">*/}
            {/*    最新项目*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/newsInformation">*/}
            {/*    新闻资讯*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </ul>
        </div>
      </header>
    )
  }
}

export default NavMobile;
