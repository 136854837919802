import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router/router'
import './assets/css/reset.css'
import './assets/js/rem'

import {caculateRem} from './assets/js/rem'
caculateRem();
window.addEventListener('resize', caculateRem);


ReactDOM.render(
  <Router />,
  document.getElementById('root')
);
