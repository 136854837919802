/**
 * File Description:
 * rem自动计算
 * Created by   AZheng                389566364@qq.com           09/24/2019
 * CopyRight    北京小悟科技有限公司      http://www.microwu.com
 * Update History:
 * Author       Time            Content
 */

export function caculateRem () {
  var styleN = document.createElement("style");
  var width = (document.documentElement.clientWidth/375)*16;
  if (document.documentElement.clientWidth < 1000) {
    styleN.innerHTML = 'html{font-size:'+width+'px!important}';
    document.head.appendChild(styleN);
  }
}
