import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import ScrollToTop from './scrollToTop'

// layout
import HomeRouter from '../layout/homeRouter';
// import AboutUsRouter from "../layout/aboutUsRouter";
// import serviceDetailPage from '../layout/web/serviceDetailPage';
// import LatestProjectsPage from '../layout/web/latestProjectsPage';
// import NewsInformation from '../layout/web/newsInformation';
// import ProjectDetailPage from '../layout/web/projectDetailPage';

const RouterConfig = () => (
  <BrowserRouter basename="">
    <ScrollToTop>
        <Route path="/" exact component={HomeRouter}>
        </Route>
        {/*<Route path="/aboutUs" component={AboutUsRouter}>*/}
        {/*</Route>*/}
        {/*<Route path="/serviceDetailPage" component={serviceDetailPage}>*/}
        {/*</Route>*/}
        {/*<Route path="/latestProjects" component={LatestProjectsPage}>*/}
        {/*</Route>*/}
        {/*<Route path="/newsInformation" component={NewsInformation}>*/}
        {/*</Route>*/}
        {/*<Route path="/projectDetailPage" component={ProjectDetailPage}>*/}
        {/*</Route>*/}
    </ScrollToTop>
  </BrowserRouter>
);

export default RouterConfig;
