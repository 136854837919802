import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'

class ProjectsMobile extends React.Component {
  componentDidMount() {
    new Swiper('.project-swiper-container', {
      initialSlide: 0,
      slidesPerView: 1,
      centeredSlides: true,
      paginationClickable: false,
      spaceBetween: 30,
      autoplay: {
        delay: 2000, // 自动切换的时间间隔
        disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay
      }
    });
  }
  render() {
    return (
      <div id="projectsHome">
        <h2 className="title">合作项目</h2>
        <div className="project-swiper-container">
          <ul className="swiper-wrapper">
            <li className="swiper-slide">
              <div className="slide-image"><img src={require("../../../assets/img/mobile/projectsSlide01.jpg")} alt="" /></div>
              <div className="content">
                “王钻商城”是联通推出的虚拟货币“王钻”，通过阶段性活动赠送给联通2I用户，用户使用王钻可以在手厅“王钻商城”活动下进入，自由兑换或者低价购买到种类丰富的互联网权益。
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default ProjectsMobile;
