import React from 'react'

import Homepage from './web/homepage'
import HomepageMobile from "./mobile/homepageMobile";
import {GetMobelType} from '../assets/js/common'

class HomeRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    }
  }
  componentDidMount() {
    if (GetMobelType().mobile) {
      this.setState({
        isMobile: true
      })
    }
  }

  showPage() {
    return this.state.isMobile ?
        <HomepageMobile /> :
        <Homepage />;
  }

  render() {
    return (
      <div className="home-router">
        {this.showPage()}
      </div>
    )
  }
}

export default HomeRouter;
