import React from 'react'
import '../../assets/css/mobile.css'
import HeaderMobile from "../../components/mobile/home/header";
import AboutUsMobile from "../../components/mobile/home/aboutUs";
import FooterMobile from "../../components/mobile/commonFooter";
import PartnersMobile from "../../components/mobile/home/partners";
import NewsMobile from "../../components/mobile/home/news";
import ChargingMobile from "../../components/mobile/home/charging";
import ProjectsMobile from "../../components/mobile/home/projects";

class HomepageMobile extends React.Component {
  render() {
    return (
      <div className="homeMobile">
        <HeaderMobile />
        <AboutUsMobile />
        <ProjectsMobile />
        <NewsMobile />
        <ChargingMobile />
        <PartnersMobile />
        <FooterMobile />
      </div>
    )
  }
}

export default HomepageMobile;
