import React from 'react'

class PartnersMobile extends React.Component {
  toDetail(){
    window.location.href = 'https://www.zhipin.com/job_detail/?query=%E5%B0%8F%E6%82%9F%E7%A7%91%E6%8A%80&city=101010100&source=9'
  }
  render() {
    return (
      <div id="partnersHome">
        {/*<h4>合作伙伴</h4>*/}
        <h2 className="title">合作伙伴</h2>
        <ul>
          <li><img src={require("../../../assets/img/mobile/partners1.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/mobile/partners2.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/mobile/partners3.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/mobile/partners4.png")} alt="partners" /></li>
        </ul>
        <ul>
          <li><img src={require("../../../assets/img/mobile/partners5.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/mobile/partners6.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/mobile/partners7.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/mobile/partners8.png")} alt="partners" /></li>
        </ul>
        <h2 className="title">加入我们</h2>
        <img className={'joinUs'} src={require("../../../assets/img/mobile/joinUs.png")} onClick={()=>this.toDetail()} alt="" />
      </div>
    )
  }
}

export default PartnersMobile;
