import React from 'react'

class Partners extends React.Component {
  render() {
    return (
      <div id="partners">
        <h4 style={{display:'none'}}>合作伙伴</h4>
        <h2>合作伙伴</h2>
        <ul>
          <li><img src={require("../../../assets/img/partners_1.png")} alt="partners" /></li>
          {/*<li><img src={require("../../../assets/img/partners_2.png")} alt="partners" /></li>*/}
          <li><img src={require("../../../assets/img/partners_3.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/partners_4.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/partners_5.png")} alt="partners" /></li>
        </ul>
        <ul>
          <li><img src={require("../../../assets/img/partners_6.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/partners_7.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/partners_8.png")} alt="partners" /></li>
          <li><img src={require("../../../assets/img/partners_9.png")} alt="partners" /></li>
          {/*<li><img src={require("../../../assets/img/partners_10.png")} alt="partners" /></li>*/}
        </ul>
      </div>
    )
  }
}

export default Partners;
