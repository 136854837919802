import React from 'react'

class FooterMobile extends React.Component {
  render() {
    return (
      <footer id="footerHome">
        <ul className="info">
          <li>
            <div className="top">
              <div className="icon-phone"></div>
              <p>联系电话</p>
            </div>
            <div className="bottom">
              <p>010-6739-6329<br/>185-0136-4533</p>
            </div>
          </li>
          <li>
            <div className="top">
              <div className="icon-mail"></div>
              <p>企业邮箱</p>
            </div>
            <div className="bottom">
              <p>hr@microwu.com</p>
            </div>
          </li>
          <li>
            <div className="top">
              <div className="icon-address"></div>
              <p>地址</p>
            </div>
            <div className="bottom">
              <p>北京市朝阳区化工路59号<br/>焦奥中心B座205室</p>
            </div>
          </li>
        </ul>
        <div className="copyright">© 2019 小悟科技 . All Rights Reserved.</div>
      </footer>
    )
  }
}

export default FooterMobile;
