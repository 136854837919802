import React from 'react'
// import Swiper from 'swiper/js/swiper.js'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'

class Team extends React.Component {
    componentWillUnmount() {
        if (this.swiper) { // 销毁swiper
            this.swiper.destroy()
        }
    }
    componentDidMount() {
        this.setState({
            chargeSwiper: new Swiper('.charging-container', {
                initialSlide: 1,
                slidesPerView: 3,
                loop: false,
                autoplay: {
                    delay: 2000, // 自动切换的时间间隔
                    disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay
                }
            })
        });
        // this.swiper = new Swiper('.member-container', {
        //   initialSlide: 0,
        //   slidesPerView: 6,
        //   spaceBetween: 30,
        //   navigation: {
        //     nextEl: '.prev-member',
        //     prevEl: '.next-member',
        //   },
        // });
        // let memberCon = document.getElementsByClassName('member-container')[0].getElementsByTagName('ul')[0];
        // let sliderArr = memberCon.children;
        // for (let i=0; i<sliderArr.length; i++) {
        //   sliderArr[i].onclick = function() {
        //     for (let j=0; j<sliderArr.length; j++) {
        //       sliderArr[j].classList.remove('active-member');
        //     }
        //     sliderArr[i].classList.add('active-member');
        //     document.getElementsByClassName('short-description')[0].innerHTML = sliderArr[i].querySelector('.member-desc').innerHTML;
        //   };
        // }
    }

    slideToPrev() {
        this.swiper.slidePrev();
    }

    slideToNext() {
        this.swiper.slideNext();
    }
    toDetail() {
        window.location.href = 'https://www.zhipin.com/job_detail/?query=%E5%B0%8F%E6%82%9F%E7%A7%91%E6%8A%80&city=101010100&source=9'
    }
    render() {
        return (
            <div id="team">
                <div className="header-row">
                    <h2>加入我们</h2>
                    <h3>To&nbsp;&nbsp;Join&nbsp;&nbsp;Us</h3>
                </div>
                <div id="charging-items">
                    <div className="charging-container">
                        <ul className="swiper-wrapper">
                            <li className="swiper-slide">
                                <div className={'title'}>前端工程师 14薪</div>
                                <div className={'price'}>8-13K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>JAVA开发工程师 14薪</div>
                                <div className={'price'}>8-13K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>客服运营 14薪</div>
                                <div className={'price'}>8-13K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>大专</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>QT开发工程师 14薪</div>
                                <div className={'price'}>10-15K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>测试工程师 14薪</div>
                                <div className={'price'}>8-13K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>不限</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>B端运营 14薪</div>
                                <div className={'price'}>8-13K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>QT开发工程师 14薪</div>
                                <div className={'price'}>8-13K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>运维工程师 14薪</div>
                                <div className={'price'}>10-15K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>运维工程师 14薪</div>
                                <div className={'price'}>9-13K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>测试工程师 14薪</div>
                                <div className={'price'}>10-15K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>Android 14薪</div>
                                <div className={'price'}>13-20K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>JAVA开发工程师 14薪</div>
                                <div className={'price'}>10-15K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>产品经理 14薪</div>
                                <div className={'price'}>8-10K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>大专</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                            <li className="swiper-slide">
                                <div className={'title'}>产品经理 14薪</div>
                                <div className={'price'}>8-13K·14薪</div>
                                <div className={'odds'}>
                                    <span>北京</span>
                                    <span>1-3年</span>
                                    <span>本科</span>
                                </div>
                                <div className={'toDetail'} onClick={() => this.toDetail()}>查看详情</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Team;
