import React from 'react'
// import { Link } from 'react-router-dom';

class AboutUsMobile extends React.Component {
    render() {
        return (
            <div id="aboutUsHome">
                <div className="header-row">
                    <h2 className="title">关于我们</h2>
                    <h3 className="sub-title">About&nbsp;&nbsp;us</h3>
                    <h4 className="title-desc">专注 高性能计算 高并发系统 大数据开发<br />提供先进的产品和服务体验</h4>
                </div>
                <ul className="about-us-row">
                    <li className="row-item">
                        <div className="image">
                            <img src={require('../../../assets/img/mobile/aboutUs01.png')} alt="about us" />
                        </div>
                        <h3 className="title">移动互联网<br />Mobile Internet</h3>
                        <p className="desc">移动互联网技术架构带来更加灵活的开发体验</p>
                    </li>
                    <li className="row-item">
                        <div className="image">
                            <img src={require('../../../assets/img/mobile/aboutUs02.png')} alt="about us" />
                        </div>
                        <h3 className="title">大数据<br />Big Data</h3>
                        <p className="desc">专业的大数据处理、存储、挖掘能力带来产品创新</p>
                    </li>
                    <li className="row-item">
                        <div className="image">
                            <img src={require('../../../assets/img/mobile/aboutUs03.png')} alt="about us" />
                        </div>
                        <h3 className="title">定位平台<br />Location</h3>
                        <p className="desc">通过小悟科技高性能、高并发查询引擎来处理定位</p>
                    </li>
                </ul>
                {/*<div className="link-btn">*/}
                {/*  <Link to="/aboutUs">了解更多></Link>*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default AboutUsMobile;
