import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'

class ChargingMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chargingFlag: '2',
      chargeSwiper: null
    }
  }

  componentDidMount() {
    this.setState({
      chargeSwiper: new Swiper('.charging-container-mobile', {
        initialSlide :3,
        slidesPerView: 3,
        centeredSlides: true,
        // autoplay: 3000
      })
    });
  }

  showChargingItem(flag) {
    this.setState({
      chargingFlag: flag
    });
    switch (flag) {
      case '1':
        this.state.chargeSwiper.slideTo(0, 600, false);
        break;
      case '2':
        this.state.chargeSwiper.slideTo(3, 600, false);
        break;
      case '3':
        this.state.chargeSwiper.slideTo(7, 600, false);
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div id="chargingHome" style={{display:"none"}}>
        {/*<div className="bg-container">*/}
        {/*  <img src={require("../../../assets/img/mobile/chargePlanBg.png")} alt=""/>*/}
        {/*</div>*/}
        {/*<h4>高性价比</h4>*/}
        {/*<h2>我们的收费计划</h2>*/}
        {/*<h5>选择合适的网络技术请联系我们寻求帮助</h5>*/}
        {/*<div className="charging-nav">*/}
        {/*  <span onClick={()=>{this.showChargingItem('1')}} id="charge_nav_1" className={['can-click', this.state.chargingFlag === '1'?"charging-active":null].join(' ')}>短信业务</span>*/}
        {/*  <span onClick={()=>{this.showChargingItem('2')}} id="charge_nav_2" className={['can-click', this.state.chargingFlag === '2'?"charging-active":null].join(' ')}>云主机服务</span>*/}
        {/*  <span onClick={()=>{this.showChargingItem('3')}} id="charge_nav_3" className={['can-click', this.state.chargingFlag === '3'?"charging-active":null].join(' ')}>充值业务</span>*/}
        {/*</div>*/}
        {/*<div className="charging-container-mobile">*/}
        {/*  <ul className="swiper-wrapper">*/}
        {/*    <li className="swiper-slide">*/}
        {/*      <div className="charge-top-part">*/}
        {/*        <h4>测试包</h4>*/}
        {/*        <h5>5</h5>*/}
        {/*        <p>元 / 100条</p>*/}
        {/*      </div>*/}
        {/*      <div className="charge-bottom-part">*/}
        {/*        <p>覆盖全球<br/>200多个国家和地区<br/>10万家企业级客户<br/>无需自行搭建消息服务<br/>免运维</p>*/}
        {/*        <div><span className="can-click">马上购买</span></div>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*    <li className="swiper-slide">*/}
        {/*      <div className="charge-top-part">*/}
        {/*        <h4>验证码</h4>*/}
        {/*        <h5>0.1</h5>*/}
        {/*        <p>元 / 条</p>*/}
        {/*      </div>*/}
        {/*      <div className="charge-bottom-part">*/}
        {/*        <p>语音验证<br/>成功性高<br/>可根据用户诉求灵活显示号码<br/>轻松实现<br/>短信验证码</p>*/}
        {/*        <div><span className="can-click">马上购买</span></div>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*    <li className="swiper-slide">*/}
        {/*      <div className="charge-top-part">*/}
        {/*        <h4>行业短信</h4>*/}
        {/*        <h5>0.05</h5>*/}
        {/*        <p>元 / 条</p>*/}
        {/*      </div>*/}
        {/*      <div className="charge-bottom-part">*/}
        {/*        <p>覆盖全球<br/>200多个国家和地区<br/>10万家企业级客户<br/>无需自行搭建消息服务<br/>免运维</p>*/}
        {/*        <div><span className="can-click">马上购买</span></div>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*    <li className="swiper-slide">*/}
        {/*      <div className="charge-top-part">*/}
        {/*        <h4>入门型</h4>*/}
        {/*        <h5>9</h5>*/}
        {/*        <p>元 / 月</p>*/}
        {/*      </div>*/}
        {/*      <div className="charge-bottom-part">*/}
        {/*        <p>40MB/s带宽峰值<br/>300GB磁盘容量<br/>磁盘额外SATA备份磁盘<br/>1个允许的域名<br/>10个子域</p>*/}
        {/*        <div><span className="can-click">马上购买</span></div>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*    <li className="swiper-slide">*/}
        {/*      <div className="charge-top-part">*/}
        {/*        <h4>标准型</h4>*/}
        {/*        <h5>19</h5>*/}
        {/*        <p>元 / 月</p>*/}
        {/*      </div>*/}
        {/*      <div className="charge-bottom-part">*/}
        {/*        <p>100MB/s带宽峰值<br/>1000GB磁盘容量<br/>磁盘额外SATA备份磁盘<br/>1个允许的域名<br/>10个子域</p>*/}
        {/*        <div><span className="can-click">马上购买</span></div>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*    <li className="swiper-slide">*/}
        {/*      <div className="charge-top-part">*/}
        {/*        <h4>进阶型</h4>*/}
        {/*        <h5>39</h5>*/}
        {/*        <p>元 / 月</p>*/}
        {/*      </div>*/}
        {/*      <div className="charge-bottom-part">*/}
        {/*        <p>150MB/s带宽峰值<br/>2500GB磁盘容量<br/>磁盘额外SATA备份磁盘<br/>1个允许的域名<br/>10个子域</p>*/}
        {/*        <div><span className="can-click">马上购买</span></div>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*    <li className="swiper-slide">*/}
        {/*      <div className="charge-top-part">*/}
        {/*        <h4>测试包</h4>*/}
        {/*        <h5>5</h5>*/}
        {/*        <p>元 / 100次</p>*/}
        {/*      </div>*/}
        {/*      <div className="charge-bottom-part">*/}
        {/*        <p>便捷稳定<br/>有高扩展性<br/>实现快速充值<br/>稳定渠道<br/>适用于互联网平台</p>*/}
        {/*        <div><span className="can-click">马上购买</span></div>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*    <li className="swiper-slide">*/}
        {/*      <div className="charge-top-part">*/}
        {/*        <h4>联通</h4>*/}
        {/*        <h5>0.2%</h5>*/}
        {/*        <p>佣金</p>*/}
        {/*      </div>*/}
        {/*      <div className="charge-bottom-part">*/}
        {/*        <p>便捷稳定<br/>有高扩展性<br/>稳定的系统支持<br/>安心的专业认证<br/>实现快速充值</p>*/}
        {/*        <div><span className="can-click">马上购买</span></div>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*    <li className="swiper-slide">*/}
        {/*      <div className="charge-top-part">*/}
        {/*        <h4>移动电信</h4>*/}
        {/*        <h5>0.5%</h5>*/}
        {/*        <p>佣金</p>*/}
        {/*      </div>*/}
        {/*      <div className="charge-bottom-part">*/}
        {/*        <p>便捷稳定<br/>有高扩展性<br/>实现快速充值<br/>稳定渠道<br/>适用于互联网平台</p>*/}
        {/*        <div><span className="can-click">马上购买</span></div>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
      </div>
    )
  }
}

export default ChargingMobile;
